<template>
  <div :id="message._id" ref="message" class="vac-message-wrapper">
    <div v-if="showDate && getHeaderText(message)" class="vac-card-info vac-card-date">
      {{ getHeaderText(message) }}
    </div>
    <!-- <div
      v-if="newMessage._id === message._id"
      class="vac-line-new"
    >
      {{ textMessages.NEW_MESSAGES }}
    </div> -->
    <div v-if="message.system" class="vac-card-info vac-card-system">
      <format-message
        :content="message.content"
        :users="roomUsers"
        :text-formatting="textFormatting"
        :link-options="linkOptions"
        @open-user-tag="openUserTag"
      >
        <template v-for="(i, name) in $scopedSlots" #[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </format-message>
    </div>

    <div
      v-else
      :id="idText"
      class="vac-message-box d-flex"
      :class="{
        'vac-offset-current': !showCheckbox && message.fromMe,
        'justify-space-between align-center vac-offset-current-checkbox': showCheckbox && message.fromMe,
        'selected-checkbox': message.selected,
        'vac-other-chat': !showCheckbox && !message.fromMe,
        'vac-offset-current-checkbox pr-150': showCheckbox && !message.fromMe,
      }"
    >
      <transition name="vac-fade-message">
        <span
          v-if="showCheckbox"
          :class="{ 'align-self-center pl-40': showCheckbox, 'mr-120': message.fromMe, 'pl-auto': !message.fromMe }"
        >
          <v-simple-checkbox v-model="message.selected" color="#1F979E" :ripple="false"></v-simple-checkbox>
        </span>
      </transition>
      <slot name="message" v-bind="{ message }">
        <div
          v-if="message.avatar && message.senderId !== currentUserId"
          class="vac-avatar"
          :style="{ 'background-image': `url('${message.avatar}')` }"
        />

        <div
          class="vac-message-container"
          :class="{
            'vac-message-container-offset': messageOffset,
            'file-container': message.files.length,
          }"
          style="max-width: 80%"
        >
          <div
            class="vac-message-card"
            :class="{
              'vac-message-highlight': isMessageHover,
              'vac-message-current': message.fromMe,
              'vac-message-deleted': message.deleted || isNotSupported,
            }"
            @mouseover="onHoverMessage"
            @mouseleave="onLeaveMessage"
          >
            <template>
              <div v-if="isForwarded" class="icon-forwarded">
                <v-icon size="22">
                  {{ icons.mdiShare }}
                </v-icon>
                Forwarded
              </div>
            </template>
            <div
              v-if="isGroup && !message.fromMe && (messageOffset || !sameDay)"
              class="vac-text-username"
              :class="{
                'vac-username-reply': !message.deleted && message.replyMessage,
              }"
            >
              <span>{{ getUsername(message) }}</span>
            </div>

            <message-reply
              v-if="!message.deleted && message.replyMessage"
              :message="message"
              :is-group="isGroup"
              :current-user-id="currentUserId"
              :room-name="roomName"
              :room-users="roomUsers"
              :text-formatting="textFormatting"
              :link-options="linkOptions"
              @click.native="scrollToMessage"
              @open-file="openFile"
            >
              <template v-for="(i, name) in $scopedSlots" #[name]="data">
                <slot :name="name" v-bind="data" />
              </template>
            </message-reply>

            <div v-if="message.deleted">
              <slot name="deleted-icon">
                <svg-icon name="deleted" class="vac-icon-deleted" />
              </slot>
              <span>{{ textMessages.MESSAGE_DELETED }}</span>
            </div>
            <div v-else-if="isNotSupported">
              <slot name="deleted-icon">
                <svg-icon name="deleted" class="vac-icon-deleted" />
              </slot>
              <span>{{ textMessages.NOT_SUPPORTED }}</span>
            </div>

            <format-message
              v-else-if="!message.files || !message.files.length"
              :content="message.content"
              :users="roomUsers"
              :text-formatting="textFormatting"
              :link-options="linkOptions"
              @open-user-tag="openUserTag"
            >
              <template v-for="(i, name) in $scopedSlots" #[name]="data">
                <slot :name="name" v-bind="data" />
              </template>
            </format-message>
            <message-files
              v-else-if="!isAudio && message.files.length"
              :current-user-id="currentUserId"
              :message="message"
              :fromChatwoot="fromChatwoot"
              :room-users="roomUsers"
              :text-formatting="textFormatting"
              :link-options="linkOptions"
              @open-file="openFile"
              @open-user-tag="openUserTag"
            >
              <template v-for="(i, name) in $scopedSlots" #[name]="data">
                <slot :name="name" v-bind="data" />
              </template>
            </message-files>

            <template v-else>
              <audio-player
                :message-id="message._id"
                :src="message.files[0].url"
                @update-progress-time="progressTime = $event"
                @hover-audio-progress="hoverAudioProgress = $event"
              >
                <template v-for="(i, name) in $scopedSlots" #[name]="data">
                  <slot :name="name" v-bind="data" />
                </template>
              </audio-player>

              <div v-if="!message.deleted" class="vac-progress-time">
                {{ progressTime }}
              </div>
            </template>

            <div
              v-if="
                message.data.buttonOptions &&
                (message.data.buttonOptions.header ||
                  message.data.buttonOptions.content ||
                  message.data.buttonOptions.footer)
              "
            >
              <div v-if="message.data.buttonOptions.header" class="font-weight-bold mb-1">
                <format-message
                  :content="message.data.buttonOptions.header"
                  :users="roomUsers"
                  :all-contacts="allContacts"
                  :text-formatting="textFormatting"
                  :link-options="linkOptions"
                  @open-user-tag="openUserTag"
                >
                  <template v-for="(i, name) in $scopedSlots" #[name]="data">
                    <slot :name="name" v-bind="data" />
                  </template>
                </format-message>
              </div>
              <div v-if="message.buttonOptions.content" class="mb-1">
                <format-message
                  :content="message.buttonOptions.content"
                  :users="roomUsers"
                  :all-contacts="allContacts"
                  :text-formatting="textFormatting"
                  :link-options="linkOptions"
                  @open-user-tag="openUserTag"
                >
                  <template v-for="(i, name) in $scopedSlots" #[name]="data">
                    <slot :name="name" v-bind="data" />
                  </template>
                </format-message>
              </div>
              <div v-if="message.buttonOptions.footer" class="text-caption mb-1">
                <format-message
                  :content="message.buttonOptions.footer"
                  :all-contacts="allContacts"
                  :users="roomUsers"
                  :text-formatting="textFormatting"
                  :link-options="linkOptions"
                  @open-user-tag="openUserTag"
                >
                  <template v-for="(i, name) in $scopedSlots" #[name]="data">
                    <slot :name="name" v-bind="data" />
                  </template>
                </format-message>
              </div>
              <v-divider class="mb-1"></v-divider>
            </div>

            <div v-if="message.data.type === 'location'" class="mt-3 mb-3">
              <gmapsMap
                :options="{
                  center: { lat: message.data.lat, lng: message.data.lng },
                  zoom: 20,
                }"
                style="width: 200px; height: 100px"
                @click="openUrlButton(`https://maps.google.com/?q=${message.data.lat},${message.data.lng}`)"
              >
                <gmapsMarker
                  :position="{ lat: message.data.lat, lng: message.data.lng }"
                  @click="openUrlButton(`https://maps.google.com/?q=${message.data.lat},${message.data.lng}`)"
                />
              </gmapsMap>
            </div>

            <div v-if="message.data.isButtons && message.data.isButtons.length > 0">
              <v-col v-for="button in message.data.isButtons" :key="button.index" cols="12" class="py-1">
                <template v-if="button.urlButton">
                  <v-btn color="primary" outlined small @click="openUrlButton(button.urlButton.url)">
                    <v-icon left>
                      {{ icons.mdiLink }}
                    </v-icon>
                    {{ button.urlButton.displayText }}
                  </v-btn>
                </template>
                <template v-else-if="button.quickReplyButton">
                  <v-btn color="primary" outlined small class="no-text-transform">
                    <span class="long-reply">
                      <v-icon left>
                        {{ icons.mdiReply }}
                      </v-icon>
                      {{ button.quickReplyButton.displayText }}
                    </span>
                  </v-btn>
                </template>
                <template v-else-if="button.callButton">
                  <v-btn color="primary" outlined small @click="openCallButton(button.callButton.phoneNumber)">
                    <v-icon left>
                      {{ icons.mdiPhone }}
                    </v-icon>
                    {{ button.callButton.displayText }}
                  </v-btn>
                </template>
              </v-col>
            </div>

            <div class="vac-text-timestamp">
              <div v-if="message.edited && !message.deleted" class="vac-icon-edited">
                <slot name="pencil-icon">
                  <svg-icon name="pencil" />
                </slot>
              </div>
              <template v-if="message.labels && message.labels.length > 0">
                <v-tooltip v-for="label in message.labels" :key="label.id" right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon fab size="18px" v-bind="attrs" :color="label.color" @click="goToLabel()" v-on="on">
                      {{ icons.mdiLabel }}
                    </v-icon>
                  </template>
                  <span>{{ label.name }}</span>
                </v-tooltip>
                <!-- <v-icon v-for="label in message.labels" :key="label.expand.tag.id" :color="label.expand.tag.color">{{
                  icons.mdiLabel
                }}</v-icon> -->
              </template>
              <span>{{ message.timestamp }}</span>
              <span>
                {{ message.fromMe ? (message.hasOwnProperty('operator') ? message.operator : '') : '' }}
              </span>
              <!-- <span>
                {{
                  message.fromMe ? (index === messages.length - 1 ? localLastMessage.operator : message.operator) : ''
                }}
              </span> -->
              <span v-if="isCheckmarkVisible">
                <slot name="checkmark-icon" v-bind="{ message }">
                  <svg-icon
                    :name="message.distributed ? 'double-checkmark' : 'checkmark'"
                    :param="message.seen ? 'seen' : ''"
                    class="vac-icon-check"
                  />
                </slot>
              </span>
            </div>

            <message-actions
              :current-user-id="currentUserId"
              :message="message"
              :index="index"
              :message-actions="messageActions"
              :fromChatwoot="fromChatwoot"
              :room-footer-ref="roomFooterRef"
              :show-reaction-emojis="showReactionEmojis"
              :hide-options="hideOptions"
              :message-hover="messageHover"
              :hover-message-id="hoverMessageId"
              :hover-audio-progress="hoverAudioProgress"
              @hide-options="$emit('hide-options', false)"
              @update-message-hover="messageHover = $event"
              @update-options-opened="optionsOpened = $event"
              @update-emoji-opened="emojiOpened = $event"
              @message-action-handler="messageActionHandler"
              @send-message-reaction="sendMessageReaction"
            >
              <template v-for="(i, name) in $scopedSlots" #[name]="data">
                <slot :name="name" v-bind="data" />
              </template>
            </message-actions>
          </div>

          <message-reactions
            :current-user-id="currentUserId"
            :message="message"
            @send-message-reaction="sendMessageReaction"
          />
        </div>
        <!-- <div
          v-if="message.avatar && message.fromMe"
          class="vac-avatar vac-avatar-current"
          :style="{ 'background-image': `url('${message.avatar}')` }"
        /> -->
        <!-- <div
          v-if="indexMessageSearch > -1"
          id="searched-message-container"
        ></div> -->
        <!-- <div
          v-if="index == messages.length - 1"
          id="last-message-container"
        ></div> -->
      </slot>
    </div>
  </div>
</template>

<script>
import { mdiLabel, mdiLink, mdiPhone, mdiReply, mdiShare } from '@mdi/js'
import { gmapsMap, gmapsMarker } from 'x5-gmaps'
import FormatMessage from '../../components/FormatMessage/FormatMessage'
import SvgIcon from '../../components/SvgIcon/SvgIcon'
import AudioPlayer from './AudioPlayer/AudioPlayer'
import MessageActions from './MessageActions/MessageActions'
import MessageFiles from './MessageFiles/MessageFiles'
import MessageReactions from './MessageReactions/MessageReactions'
import MessageReply from './MessageReply/MessageReply'
const { messagesValidation } = require('../../utils/data-validation')
const { isAudioFile } = require('../../utils/media-file')

export default {
  name: 'Message',
  components: {
    SvgIcon,
    FormatMessage,
    AudioPlayer,
    MessageReply,
    MessageFiles,
    MessageActions,
    MessageReactions,
    gmapsMap,
    gmapsMarker,
  },

  props: {
    currentUserId: { type: [String, Number], required: true },
    textMessages: { type: Object, required: true },
    index: { type: Number, required: true },
    message: { type: Object, required: true },
    messages: { type: Array, required: true },
    allContacts: { type: Array, required: true },
    editedMessage: { type: Object, required: true },
    roomUsers: { type: Array, default: () => [] },
    messageActions: { type: Array, required: true },
    roomFooterRef: { type: HTMLDivElement, default: null },
    newMessages: { type: Array, default: () => [] },
    showReactionEmojis: { type: Boolean, required: true },
    showNewMessagesDivider: { type: Boolean, required: true },
    textFormatting: { type: Object, required: true },
    linkOptions: { type: Object, required: true },
    hideOptions: { type: Boolean, required: true },
    fromChatwoot: { type: Boolean, required: true },
    isGroup: { type: Boolean, required: true },
    roomName: { type: String, required: true },

    showCheckbox: { type: Boolean, default: false },
    indexMessageSearch: { type: Number, default: -1 },
    indexMessageHashtag: { type: Number, default: -1 },
  },

  emits: [
    'hide-options',
    'message-added',
    'open-file',
    'open-user-tag',
    'message-action-handler',
    'send-message-reaction',
  ],

  data() {
    return {
      hoverMessageId: null,
      messageHover: false,
      optionsOpened: false,
      emojiOpened: false,
      newMessage: {},
      progressTime: '- : -',
      hoverAudioProgress: false,
      icons: {
        mdiLink,
        mdiLabel,
        mdiPhone,
        mdiReply,
        mdiShare,
      },
      localLastMessage: {},
    }
  },

  computed: {
    showDate() {
      if (this.index === 0) {
        return true
      }

      return this.index > 0 && this.message.date !== this.messages[this.index - 1].date
    },
    messageOffset() {
      return this.index > 0 && this.message.senderId !== this.messages[this.index - 1].senderId
    },
    isMessageHover() {
      return this.editedMessage._id === this.message._id || this.hoverMessageId === this.message._id
    },
    isAudio() {
      return this.message.files?.some(file => isAudioFile(file))
    },
    isCheckmarkVisible() {
      return this.message.fromMe && !this.message.deleted && (this.message.distributed || this.message.seen)
    },
    isNotSupported() {
      return (
        (this.message.content === null ||
          this.message.content === undefined ||
          Object.keys(this.message.content).length === 0) &&
        (!this.message.files || this.message.files.length === 0) &&
        Object.keys(this.message.original_message).length > 0 &&
        !this.message.lat &&
        !this.message.lng
      )
    },

    // showCheckbox() {
    //   return this.$store.getters['inbox/getShowCheckbox']
    // },
    idText() {
      if (this.indexMessageSearch > -1 && this.index === this.indexMessageSearch) return 'searched-message-container'
      if (this.indexMessageHashtag > -1 && this.index === this.indexMessageHashtag) return 'hashtag-message-container'
      if (this.index === this.messages.length - 1) return 'last-message-container'
      else return 'typical-message'
    },

    isForwarded() {
      if (
        this.message?.forwarded ||
        this.message?.original_message?.message?.extendedTextMessage?.contextInfo?.isForwarded
      ) {
        return true
      }

      return false
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
  },

  watch: {
    newMessages: {
      immediate: true,
      deep: true,
      handler(val) {
        if (!val.length || !this.showNewMessagesDivider) {
          return (this.newMessage = {})
        }

        this.newMessage = val.reduce((res, obj) => (obj.index < res.index ? obj : res))
      },
    },
    message: {
      immediate: true,
      deep: true,
      handler(val) {
        // console.log(val, 'masuk watcher<<<')
        if (!val.chatId && val.fromMe) {
          setTimeout(async () => {
            this.localLastMessage = JSON.parse(JSON.stringify(val))
            // const msg = await meiliClient.index(`${this.user.sub_id}_messages`).search(`${val.content}`, {
            //   filter: `source_id = ${val.source_id}`,
            //   limit: 1,
            // })
            // // //console.log(this.localLastMessage, this.messages[this.index], this.index, msg.hits[0], 'ini locakle')
            // if (msg.hits?.length) {
            //   this.messages[this.index].operator = msg.hits[0].operator
            //   this.localLastMessage.operator = msg.hits[0].operator
            // }
          })
          // this.localLastMessage.operator =
        }
      },
    },
  },

  mounted() {
    messagesValidation(this.message)

    this.$emit('message-added', {
      message: this.message,
      index: this.index,
      ref: this.$refs.message,
    })
  },

  methods: {
    formatPhoneNumber(phoneNumber, group = true) {
      if (!phoneNumber?.includes('-') && phoneNumber?.length <= 16) {
        const countryCode = `+${phoneNumber.substr(0, 2)}` // +62
        const splitOne = phoneNumber.substr(2, 3)
        const splitTwo = phoneNumber.substr(5, 4)
        const splitThree = phoneNumber.substr(9, phoneNumber.length - 1)
        phoneNumber = `${countryCode} ${splitOne}-${splitTwo}-${splitThree}`
      } else if (group) {
        phoneNumber = 'Group'
      }

      return phoneNumber
    },
    getUsername(message) {
      const { sender_id } = message
      const foundUser = this.roomUsers.find(user => user._id == sender_id)

      if (!foundUser) return

      const { username, _id } = foundUser
      if (username) {
        return username
      }

      return this.formatPhoneNumber(_id)
    },
    scrollToMessage() {
      let id = ''

      if (this.message.data && this.message.data.quotedStanzaID) {
        id = this.message.data.quotedStanzaID
      }

      if (id) {
        setTimeout(() => {
          const element = document.getElementById(id)

          if (element) {
            element.scrollIntoView({
              behavior: 'smooth',
            })
          }
        }, 50)
      }
    },
    openUrlButton(url) {
      window.open(url, '_blank')
    },
    openCallButton(phoneNumber) {
      window.open(`tel:${phoneNumber}`, '_blank')
    },
    getHeaderText(date) {
      if (date.couch_timestamp) {
        if (this.$moment(+date.couch_timestamp).isSame(this.$moment(), 'day')) {
          return this.$root.$i18n.locale == 'en' ? 'Today' : 'Hari ini'
        }
        if (this.$moment(+date.couch_timestamp).isSame(this.$moment().subtract(1, 'day'), 'day')) {
          return this.$root.$i18n.locale == 'en' ? 'Yesterday' : 'Kemarin'
        }

        return this.$moment(+date.couch_timestamp).format('DD/MM/YY')
      }

      if (date.reply_time) {
        if (this.$moment.unix(date.reply_time / 1000).isSame(new Date(), 'day')) {
          return this.$root.$i18n.locale == 'en' ? 'Today' : 'Hari ini'
        }
        if (this.$moment.unix(date.reply_time / 1000).isSame(this.$moment().subtract(1, 'day'), 'day')) {
          return this.$root.$i18n.locale == 'en' ? 'Yesterday' : 'Kemarin'
        }

        return this.$moment.unix(date.reply_time / 1000).format('DD/MM/YY')
      }

      if (date.seconds) {
        if (this.$moment.unix(date.seconds).isSame(new Date(), 'day')) {
          return this.$root.$i18n.locale == 'en' ? 'Today' : 'Hari ini'
        }
        if (this.$moment.unix(date.seconds).isSame(this.$moment().subtract(1, 'day'), 'day')) {
          return this.$root.$i18n.locale == 'en' ? 'Yesterday' : 'Kemarin'
        }

        return this.$moment.unix(date.seconds).format('DD/MM/YY')
      }

      return ''
    },
    sameDay() {
      let sameDay = true
      if (
        this.index > 0 &&
        this.message &&
        this.message.date &&
        this.messages[this.index - 1] &&
        this.messages[this.index - 1].date &&
        this.message.senderId === this.messages[this.index - 1].senderId
      ) {
        sameDay = this.$moment(this.messages[this.index - 1].date, 'DD MMM YYYY').isSame(
          this.$moment(this.message.date, 'DD MMM YYYY'),
          'day',
        )
      }

      return sameDay
    },
    onHoverMessage() {
      this.messageHover = true
      if (this.canEditMessage()) this.hoverMessageId = this.message._id
    },
    canEditMessage() {
      return !this.message.deleted
    },
    onLeaveMessage() {
      if (!this.optionsOpened && !this.emojiOpened) this.messageHover = false
      this.hoverMessageId = null
    },
    openFile(file) {
      this.$emit('open-file', { message: this.message, file })
    },
    openUserTag(user) {
      // //console.log(user, '<<<okkoko')
      this.$emit('open-user-tag', user)
    },
    messageActionHandler(action) {
      this.messageHover = false
      this.hoverMessageId = null

      setTimeout(() => {
        this.$emit('message-action-handler', { action, message: this.message })
      }, 300)
    },
    sendMessageReaction({ emoji, reaction }) {
      this.$emit('send-message-reaction', {
        messageId: this.message._id,
        reaction: emoji,
        remove: reaction && reaction.indexOf(this.currentUserId) !== -1,
      })
      this.messageHover = false
    },
  },
}
</script>

<style scoped>
.icon-forwarded {
  color: #999999;
  margin-bottom: 5px;
  padding-right: 5px;
  font-style: italic;
  font-size: 14px;
}
.file-container {
  width: 350px;
}
</style>
