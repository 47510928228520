function formatString(string) {
  return string
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
}

export default (items, prop, val, startsWith = false) => {
  if (!val || val === '') return items

  return items.filter(v => {
    const formattedValue = formatString(v[prop])
    const formattedFilter = formatString(val)
    const match = startsWith ? formattedValue.startsWith(formattedFilter) : formattedValue.includes(formattedFilter)
    return match
  })
}
