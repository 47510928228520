<template>
  <transition name="vac-fade-spinner" appear>
    <div
      v-if="show"
      class="vac-loader-wrapper"
      :class="{
        'vac-container-center': !infinite && !loadingDownload,
        'vac-container-top': infinite && !loadingDownload,
        'vac-container-right': loadingDownload,
      }"
    >
      <slot name="spinner-icon" v-bind="{ show, infinite }">
        <div
          id="vac-circle"
          :class="{
            'loader-message-file': fromMessageFile,
          }"
        />
      </slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Loader',

  props: {
    show: { type: Boolean, default: false },
    infinite: { type: Boolean, default: false },
    fromMessageFile: { type: Boolean, default: false },
    loadingDownload: { type: Boolean, default: false },
  },
}
</script>

<style>
.loader-message-file {
  width: 8.5% !important;
}
</style>
