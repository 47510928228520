<template>
  <div class="vac-format-message-wrapper" :class="{ 'vac-text-ellipsis': singleLine }">
    <div v-if="!textFormatting.disabled && !notSupported" :class="{ 'vac-text-ellipsis': singleLine }">
      <div v-for="(message, i) in linkifiedMessage" :key="i" class="vac-format-container" ref="containerMessage">
        <component
          :is="message.url ? 'a' : 'span'"
          :class="{
            'vac-text-ellipsis': singleLine,
            'vac-text-bold': message.bold,
            'vac-text-italic': deleted || message.italic,
            'vac-text-strike': message.strike,
            'vac-text-underline': message.underline,
            'vac-text-inline-code': !singleLine && message.inline,
            'vac-text-multiline-code': !singleLine && message.multiline,
            'vac-text-tag': !singleLine && !reply && message.tag,
          }"
          :href="message.href"
          :target="message.href ? linkOptions.target : null"
          :rel="message.href ? linkOptions.rel : null"
          @click="openTag(message)"
        >
          <slot name="deleted-icon" v-bind="{ deleted }">
            <svg-icon v-if="deleted" name="deleted" class="vac-icon-deleted" />
          </slot>
          <template v-if="message.url && message.image">
            <!-- <div class="vac-image-link-container">
              <div
                class="vac-image-link"
                :style="{
                  'background-image': `url('${message.value}')`,
                  height: message.height
                }"
              />
            </div> -->
            <div class="vac-image-link-message">
              <span :class="isMessageLocation ? 'truncate' : ''">{{ message.value }}</span>
            </div>
          </template>
          <template v-else>
            <template>
              <span :class="(isUnread ? 'font-weight-black' : '', isMessageLocation ? 'truncate' : '')">{{
                message.value
              }}</span>
            </template>
          </template>
        </component>
      </div>
    </div>
    <div v-else-if="notSupported">
      <svg-icon name="deleted" class="vac-icon-deleted" />
      <span
        :class="{
          'vac-text-italic': notSupported,
        }"
      >
        Unsupported message format
      </span>
    </div>
    <div v-else>
      {{ formattedContent }}
    </div>
  </div>
</template>

<script>
import utilsMixin from '@/mixins/utilsMixin'
import SvgIcon from '../SvgIcon/SvgIcon'

import { IMAGE_TYPES } from '../../utils/constants'
import formatString from '../../utils/format-string'

export default {
  name: 'FormatMessage',
  components: { SvgIcon },

  mixins: [utilsMixin],

  props: {
    content: { type: [String, Number, Object], required: true },
    deleted: { type: Boolean, default: false },
    users: { type: Array, default: () => [] },
    linkify: { type: Boolean, default: true },
    allContacts: { type: Array, default: () => [] },
    singleLine: { type: Boolean, default: false },
    reply: { type: Boolean, default: false },
    textFormatting: { type: Object, required: true },
    linkOptions: { type: Object, required: true },
    isUnread: { type: Boolean, default: false },
    isMessageLocation: { type: Boolean, default: false },
    notSupported: { type: Boolean, default: false },
  },

  data() {
    return {
      userTampungan: [],
      userTampungan2: [],
    }
  },

  emits: ['open-user-tag'],

  computed: {
    linkifiedMessage() {
      if (!Object.keys(this.content).length && !this.notSupported) {
        return
      }

      if (typeof this.content === 'object') {
        return
      }

      const message = formatString(
        this.formatTags(this.content),
        this.linkify && !this.linkOptions.disabled,
        this.textFormatting,
      )

      message.forEach(m => {
        m.url = this.checkType(m, 'url')
        m.bold = this.checkType(m, 'bold')
        m.italic = this.checkType(m, 'italic')
        m.strike = this.checkType(m, 'strike')
        m.underline = this.checkType(m, 'underline')
        m.inline = this.checkType(m, 'inline-code')
        m.multiline = this.checkType(m, 'multiline-code')
        m.tag = this.checkType(m, 'tag')
        m.image = this.checkImageType(m)
      })

      return message
    },
    formattedContent() {
      return this.formatTags(this.content)
    },
  },

  mounted() {
    // //console.log('🚀 ~ file: FormatMessage.vue ~ line 290 ~ mounted ~ mounted', this.linkifiedMessage)
  },

  methods: {
    formatPhoneNumber(phoneNumber, group = true) {
      phoneNumber = phoneNumber.toString()

      // //console.log('🚀 ~ file: Chat.vue ~ line 3675 ~ formatPhoneNumber ~ phoneNumber, group = true', phoneNumber)
      if (!phoneNumber?.includes('-') && phoneNumber?.length <= 16) {
        const countryCode = `+${phoneNumber.substr(0, 2)}` // +62
        const splitOne = phoneNumber.substr(2, 3)
        const splitTwo = phoneNumber.substr(5, 4)
        const splitThree = phoneNumber.substr(9, phoneNumber.length - 1)
        phoneNumber = `${countryCode} ${splitOne}-${splitTwo}-${splitThree}`
      } else if (group) {
        phoneNumber = 'Group'
      }

      return phoneNumber
    },
    checkType(message, type) {
      return message.types.indexOf(type) !== -1
    },
    checkImageType(message) {
      let index = message.value.lastIndexOf('.')
      const slashIndex = message.value.lastIndexOf('/')
      if (slashIndex > index) index = -1

      const type = message.value.substring(index + 1, message.value.length)

      const isMedia = index > 0 && IMAGE_TYPES.some(t => type.toLowerCase().includes(t))

      if (isMedia) this.setImageSize(message)

      return isMedia
    },
    setImageSize(message) {
      const image = new Image()
      image.src = message.value

      image.addEventListener('load', onLoad)

      function onLoad(img) {
        const ratio = img.path[0].width / 150
        message.height = `${Math.round(img.path[0].height / ratio)}px`
        image.removeEventListener('load', onLoad)
      }
    },
    formatTags(content) {
      // console.log('🚀 nyund ~ file: FormatMessage.vue:173 ~ formatTags ~ content:', content)
      const firstTag = '<usertag>'
      const secondTag = '</usertag>'
      let usertags = []
      if (!Object.keys(content).length) {
        return
      }
      // //console.log(content, this.reply,'ini contend')

      // if(content.includes('@') && !this.reply) {
      // let split = content.split(' ')

      // for(let i = 0; i < split.length; i++) {
      //   const word = split[i]
      //   //console.log(word,'ini usedd')
      //   if(word.startsWith('@')) {
      //     let newWord = new RegExp(word, 'g')
      //     let used = word.slice(1)
      //     content = content.replace(newWord, `${firstTag}${used}${secondTag}`)
      //   }
      // }
      // }
      if (content && typeof content !== 'object') {
        usertags = [...content.matchAll(new RegExp(firstTag, 'gi'))].map(a => a.index)
      }

      const initialContent = content
      // //console.log(initialContent, usertags,'ini dia<<<v')

      if (usertags && usertags.length > 0) {
        usertags.forEach(index => {
          const userId = initialContent.substring(index + firstTag.length, initialContent.indexOf(secondTag, index))
          const userData = this.users.find(userInfo => userInfo._id == userId)
          const user = this.$store.getters['global/getAllContactData'].find(user => user.phone_number == userId)
          const userContact = this.allContacts.find(contact => contact.phone_number == userId)

          if (user) {
            content = content.replaceAll(firstTag + userId + secondTag, `@${user.name ? user.name : userId}`)
          } else if (userData) {
            if (userData._id == userData.username.replace(/[+ -]/g, '')) {
              userData.username = this.formatPhoneNumber(userData._id, false)
            }

            content = content.replaceAll(
              userId,
              `@${userData.username ? userData.username : this.formatPhoneNumber(userData._id, false)}`,
            )
          } else {
            content = content.replaceAll(userId, `@${this.formatPhoneNumber(userId, false)}`)
          }

          // if (userContact) {
          //   if (userContact.name == userId) {
          //     if (user) {
          //       if (user.bailey_name) {
          //         userContact.name = user.bailey_name
          //       } else if (user.notify_name) {
          //         userContact.name = user.notify_name
          //       } else if (user.contact_list_name) {
          //         userContact.name = user.contact_list_name
          //       } else {
          //         userContact.name = this.formatPhoneNumber(userId, false)
          //       }
          //     } else {
          //       userContact.name = this.formatPhoneNumber(userId, false)
          //     }

          //     // userContact.username = this.formatPhoneNumber(userId, false)
          //   } else if (userContact.name !== userId) {
          //     userContact.username = userContact.name
          //   }
          //   this.userTampungan2.push(userContact)

          //   content = content.replaceAll(userId, `@${userContact.name ? userContact.name : userId}`)
          // } else if (user && user.bailey_name) {
          //   if (user._id == user.bailey_name) {
          //     user.bailey_name = this.formatPhoneNumber(user._id, false)
          //   }

          //   // if(this.userTampungan && (this.userTampungan.length === 0 || this.userTampungan.findIndex(el => {
          //   //   if(el.bailey_name) {
          //   //     el.bailey_name === user.bailey_name
          //   //   }
          //   //   }) < 0 )) {
          //   //   this.userTampungan.push(user)
          //   // }

          //   content = content.replaceAll(userId, `@${user.bailey_name ? user.bailey_name : user._id}`)
          // } else if (user && user.notify_name) {
          //   if (user._id == user.notify_name) {
          //     user.notify_name = this.formatPhoneNumber(user._id, false)
          //   }

          //   // if(this.userTampungan && (this.userTampungan.length === 0 || this.userTampungan.findIndex(el => {
          //   //   if(el.notify_name) {
          //   //     el.notify_name === user.notify_name
          //   //   }
          //   //   }) < 0 )) {
          //   //   this.userTampungan.push(user)
          //   // }
          //   let test = 0
          //   if (this.userTampungan.length !== 0) {
          //     test = this.userTampungan.findIndex(el => {
          //       if (el && el.notify_name) return el.notify_name === user.notify_name
          //     })
          //   }
          //   if (this.userTampungan.length === 0 || test < 0) {
          //     this.userTampungan.push(user)
          //   }
          //   content = content.replaceAll(userId, `@${user.notify_name ? user.notify_name : user._id}`)
          // } else if (!user) {
          //   if (userData) {
          //     if (userData._id == userData.username.replace(/[+ -]/g, '')) {
          //       userData.username = this.formatPhoneNumber(userData._id, false)
          //     }
          //     content = content.replaceAll(userId, `@${userData.username ? userData.username : userData._id}`)
          //   }
          // }
          // //console.log(user, userId, initialContent, index,'ini user')

          // content = content.replaceAll(userId, `@${user?.username || 'unknown'}`)
        })
      }

      /// INI UNTUK YG FORMATING NANTI
      // usertags.forEach(index => {
      //   const userId = initialContent.substring(index + firstTag.length, initialContent.indexOf(secondTag, index))

      //   // //console.log(userId, `INI USERTAGS`)

      //   const userData = this.users.find(userInfo => userInfo._id === userId)
      //   const user = this.$store.getters['global/getAllContactData'].find(
      //     user => user.phone_number == userId && user.notify_name !== userId,
      //   )
      //   const userContact = this.allContacts.find(contact => contact.phone_number === userId)
      //   //console.log(userContact, 'ini usercon')

      //   // if (user._id.includes('5130')) {
      //   //   //console.log(true, this.currentRoomContact, user, user.username.replace(/[+ -]/g, ''), `INI NAMA KONTAK`)
      //   // }

      //   // //console.log(userId, userContact, `INI USER CONTACT`)

      //   // //console.log(userId, this.allContacts, `INI USER ID`)
      //   // //console.log(user, `INI USER`)
      //   // if (userId == '6282260567687') {
      //   //   //console.log(
      //   //     '🚀 ~ file: FormatMessage.vue ~ line 346 ~ formatTags ~ userContact',
      //   //     userContact,
      //   //     userContact.name == userId,
      //   //     user,
      //   //   )
      //   // }
      //   this.userTampungan.push(userData)
      //   if (userContact) {
      //     if (userContact.name == userId) {
      //       if (user) {
      //         if (user.bailey_name) {
      //           userContact.name = user.bailey_name
      //         } else if (user.notify_name) {
      //           userContact.name = user.notify_name
      //         } else if (user.contact_list_name) {
      //           userContact.name = user.contact_list_name
      //         } else {
      //           userContact.name = this.formatPhoneNumber(userId, false)
      //         }
      //       } else {
      //         userContact.name = this.formatPhoneNumber(userId, false)
      //       }

      //       // userContact.username = this.formatPhoneNumber(userId, false)
      //     } else if (userContact.name !== userId) {
      //       userContact.username = userContact.name
      //     }
      //     this.userTampungan2.push(userContact)

      //     content = content.replaceAll(userId, `@${userContact.name ? userContact.name : userId}`)
      //   } else if (user && user.bailey_name) {
      //     if (user._id == user.bailey_name) {
      //       user.bailey_name = this.formatPhoneNumber(user._id, false)
      //     }
      //     // if(this.userTampungan && (this.userTampungan.length === 0 || this.userTampungan.findIndex(el => {
      //     //   if(el.bailey_name) {
      //     //     el.bailey_name === user.bailey_name
      //     //   }
      //     //   }) < 0 )) {
      //     //   this.userTampungan.push(user)
      //     // }

      //     content = content.replaceAll(userId, `@${user.bailey_name ? user.bailey_name : user._id}`)
      //   } else if (user && user.notify_name) {
      //     if (user._id == user.notify_name) {
      //       user.notify_name = this.formatPhoneNumber(user._id, false)
      //     }
      //     // if(this.userTampungan && (this.userTampungan.length === 0 || this.userTampungan.findIndex(el => {
      //     //   if(el.notify_name) {
      //     //     el.notify_name === user.notify_name
      //     //   }
      //     //   }) < 0 )) {
      //     //   this.userTampungan.push(user)
      //     // }
      //     let test = 0
      //     if(this.userTampungan.length !== 0) {
      //       test = this.userTampungan.findIndex(el=> {
      //         if(el && el.notify_name) return el.notify_name === user.notify_name
      //       })
      //     }
      //     if(this.userTampungan.length === 0 || test < 0) {
      //       this.userTampungan.push(user)
      //     }
      //     content = content.replaceAll(userId, `@${user.notify_name ? user.notify_name : user._id}`)
      //   } else if (!user) {
      //     if (userData) {
      //       if (userData._id == userData.username.replace(/[+ -]/g, '')) {
      //         userData.username = this.formatPhoneNumber(userData._id, false)
      //       }
      //       content = content.replaceAll(userId, `@${userData.username ? userData.username : userData._id}`)
      //     }
      //   }

      //   // format phone number
      // })

      // //console.log(content, 'ini contes')

      return content
    },
    openTag(message) {
      if (!this.singleLine && this.checkType(message, 'tag')) {
        const user = this.users.find(u => message.value.indexOf(u.username) !== -1)
        // //console.log(user, '<test')
        this.$emit('open-user-tag', user)
      }
    },
  },
}
</script>
