<template>
  <div ref="modal" tabindex="0" class="vac-media-preview" @keydown.esc="closeModal">
    <transition name="vac-bounce-preview" appear>
      <div v-if="isImage" class="vac-media-preview-container">
        <v-img class="image-preview" :style="onZoom ? zoomIn : zoomOut" :src="file.url" @click="zoomImg" />
        <!-- <div
          class="vac-image-preview"
          :style="{
            'background-image': `url('${file.url}')`
          }"
        /> -->
      </div>

      <div v-else-if="isVideo" class="vac-media-preview-container">
        <pre>{{ isVideo }}</pre>
        <video width="100%" height="100%" controls autoplay>
          <source :src="file.url" />
        </video>
      </div>
    </transition>

    <!-- <div class="vac-svg-button">
      <slot name="preview-close-icon">
        <svg-icon
          name="close-outline"
          param="preview"
        />
      </slot>
    </div> -->
  </div>
</template>
<script>
import SvgIcon from '../../components/SvgIcon/SvgIcon'

const { isImageFile, isVideoFile } = require('../../utils/media-file')

export default {
  name: 'MediaPreview',
  components: {
    SvgIcon,
  },
  props: {
    file: { type: Object, required: true },
  },
  data() {
    return {
      zoomIn:
        'transform: scale(2) !important; cursor: zoom-out !important; transition: transform 0.25s ease !important;',
      zoomOut:
        'transform: scale(1) !important; cursor: zoom-in !important; transition: transform 0.25s ease !important;',
      onZoom: false,
    }
  },
  computed: {
    isImage() {
      return isImageFile(this.file)
    },
    isVideo() {
      return isVideoFile(this.file)
    },
  },
  mounted() {
    this.$refs.modal.focus()
  },
  methods: {
    zoomImg() {
      this.onZoom = !this.onZoom
    },
    closeModal() {
      this.$emit('close-media-preview')
    },
  },
  emits: ['close-media-preview'],
}
</script>

<style scoped>
.image-preview {
  cursor: -moz-zoom-in !important;
  cursor: -webkit-zoom-in !important;
  cursor: zoom-in !important;
  /* height: 70%;
  width: 100%;  */
}
</style>
