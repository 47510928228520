<template>
  <transition name="vac-slide-up">
    <div
      v-if="messageReply"
      class="vac-reply-container"
      :style="{ bottom: `${$parent.$refs.roomFooter.clientHeight}px` }"
    >
      <div class="vac-reply-box">
        <div class="vac-reply-info">
          <div class="vac-reply-username">
            {{ replyUsername() }}
          </div>
          <div class="vac-reply-content">
            <format-message
              :content="truncateReplyMessage(messageReply.content)"
              :users="room.users"
              :text-formatting="textFormatting"
              :link-options="linkOptions"
              :reply="true"
            >
              <template v-for="(i, name) in $scopedSlots" #[name]="data">
                <slot :name="name" v-bind="data" />
              </template>
            </format-message>
          </div>
        </div>

        <img v-if="isImage" :src="firstFile.url" class="vac-image-reply" />

        <video v-else-if="isVideo" controls class="vac-image-reply">
          <source :src="firstFile.url" />
        </video>

        <audio-player v-else-if="isAudio" :src="firstFile.url" class="vac-audio-reply">
          <template v-for="(i, name) in $scopedSlots" #[name]="data">
            <slot :name="name" v-bind="data" />
          </template>
        </audio-player>

        <!-- <div  class="vac-image-reply vac-file-container"> -->
          <v-container v-else-if="isOtherFile" fluid >
            <v-row>
              <v-col cols="12" sm="2" offset-sm="2" md="10" class="ml-0">
                <div style="display: inline; margin-left: 2vw;">
                  <div class="message message-sent">
                    <div class="message-text">
                      <div class="message-file-icon-container">
                        <div class="message-file-icon-wrapper">
                          <img width="20" :src="previewfile(firstFile)" />
                        </div>
                      </div>
                      <div class="message-file-info">
                        <span class="message-file-name">{{ firstFile.name }}</span>
                      </div>
                      <div class="message-file-download-link vac-svg-button"">
                        <slot name="document-icon" />
                        <svg-icon name="document" />
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        
        <!-- </div> -->
      </div>

      <div class="vac-icon-reply">
        <div class="vac-svg-button" @click="$emit('reset-message')">
          <slot name="reply-close-icon">
            <svg-icon name="close-outline" />
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import FormatMessage from '../../../components/FormatMessage/FormatMessage'
import SvgIcon from '../../../components/SvgIcon/SvgIcon'

import AudioPlayer from '../../Message/AudioPlayer/AudioPlayer'

const { isAudioFile, isImageFile, isVideoFile } = require('../../../utils/media-file')

export default {
  name: 'RoomMessageReply',
  components: {
    SvgIcon,
    FormatMessage,
    AudioPlayer,
  },

  props: {
    room: { type: Object, required: true },
    messageReply: { type: Object, default: null },
    textFormatting: { type: Object, required: true },
    linkOptions: { type: Object, required: true },
  },

  emits: ['reset-message'],

  computed: {
    firstFile() {
      return this.messageReply.files?.length ? this.messageReply.files[0] : {}
    },
    isImage() {
      return isImageFile(this.firstFile)
    },
    isVideo() {
      return isVideoFile(this.firstFile)
    },
    isAudio() {
      return isAudioFile(this.firstFile)
    },
    isOtherFile() {
      return this.messageReply.files?.length && !this.isAudio && !this.isVideo && !this.isImage
    },
  },

  methods: {
    replyUsername() {
      const { senderId } = this.messageReply
      const replyUser = this.room.users.find(user => user._id === senderId)
      if (senderId === this.room.roomOwnerId && this.messageReply.fromMe) {
        return 'You'
      }

      if (this.room.phone_number_show !== 'Group') {
        return this.room.roomName
      }

      return replyUser ? replyUser.username : ''
    },
    previewfile(file) {
      if (this.fromChatwoot) {
        if (file.preview.includes('pdf')) {
          return '/file-manager/icons/application-pdf.svg'
        } else if (file.preview.includes('csv')) {
          return 'https://cdn-icons-png.flaticon.com/512/8242/8242984.png'
        } else if (file.preview.includes('xlsx')) {
          return '/file-manager/icons/application-vnd.ms-excel.svg'
        } else if (file.preview.includes('txt')) {
          return 'https://cdn-icons-png.flaticon.com/512/3979/3979306.png'
        } else if (file.preview.includes('docx')) {
          return '/file-manager/icons/application-msword-template.svg'
        } else {
          return '/file-manager/icons/text.svg'
        }
      } else {
        if (file.type === 'pdf') {
          return '/file-manager/icons/application-pdf.svg'
        } else if (file.type === 'csv') {
          return 'https://cdn-icons-png.flaticon.com/512/8242/8242984.png'
        } else if (file.type.includes('spreadsheetml') || file.name.includes('xlsx')) {
          return '/file-manager/icons/application-vnd.ms-excel.svg'
        } else if (file.type === 'plain') {
          return 'https://cdn-icons-png.flaticon.com/512/3979/3979306.png'
        } else if (file.type.includes('document')) {
          return '/file-manager/icons/application-msword-template.svg'
        } else {
          return '/file-manager/icons/text.svg'
        }
      }
    },
    truncateReplyMessage(replyMessage) {
      if (replyMessage && typeof replyMessage !== 'object') {
        if (this.$vuetify.breakpoint.xs) {
          if (replyMessage.length <= 50) return replyMessage.slice(0, 50)

          return `${replyMessage.slice(0, 50)}...`
        }
        if (replyMessage.length <= 100) {
          return `${replyMessage.slice(0, 100)}`
        }

        return `${replyMessage.slice(0, 100)}...`
      }
      return ''
    },
  },
}
</script>

<style scoped></style>
