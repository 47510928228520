const linkify = require('linkifyjs')
// require('linkifyjs/plugins/hashtag')(linkify)

export default (text, doLinkify, textFormatting) => {
  // return [{"types":[],"value":"test "},{"types":["bold"],"value":"abcd"}];
  // const typeMarkdown = {
  //   bold: textFormatting.bold,
  //   italic: textFormatting.italic,
  //   strike: textFormatting.strike,
  //   underline: textFormatting.underline,
  //   multilineCode: textFormatting.multilineCode,
  //   inlineCode: textFormatting.inlineCode,
  // }

  // const pseudoMarkdown = {
  //   [typeMarkdown.bold]: {
  //     end: '\\' + typeMarkdown.bold,
  //     allowed_chars: '[\\s\\S]',
  //     type: 'bold',
  //   },
  //   [typeMarkdown.italic]: {
  //     end: typeMarkdown.italic,
  //     allowed_chars: '[\\s\\S]',
  //     type: 'italic',
  //   },
  //   [typeMarkdown.strike]: {
  //     end: typeMarkdown.strike,
  //     allowed_chars: '[\\s\\S]',
  //     type: 'strike',
  //   },
  //   [typeMarkdown.underline]: {
  //     end: typeMarkdown.underline,
  //     allowed_chars: '[\\s\\S]',
  //     type: 'underline',
  //   },
  //   [typeMarkdown.multilineCode]: {
  //     end: typeMarkdown.multilineCode,
  //     allowed_chars: '[\\s\\S]',
  //     type: 'multiline-code',
  //   },
  //   [typeMarkdown.inlineCode]: {
  //     end: typeMarkdown.inlineCode,
  //     allowed_chars: '[\\s\\S]',
  //     type: 'inline-code',
  //   },
  //   '<usertag>': {
  //     allowed_chars: '[\\s\\S]',
  //     end: '</usertag>',
  //     type: 'tag',
  //   },
  // }

  // const json = compileToJSON(text, pseudoMarkdown)

  // const html = compileToHTML(json, pseudoMarkdown)

  // const result = [].concat.apply([], html)

  // if (doLinkify) linkifyResult(result)
  // console.log("MSG:"+JSON.stringify([text, result]))

  // return result

  const result = transformText(text)
  // if (doLinkify) linkifyResult(result)
  // console.log("MSG:"+JSON.stringify([text, result]))

  return result;
}

function transformText(text) {
  const regex = /(@[0-9]+)|<usertag>(.*?)<\/usertag>|\b\*(.*?)\*\b|\b_(.*?)_\b|\b~(.*?)~\b|((https?:\/\/)?([a-z0-9-#_+%?=]+\.)*[a-z0-9-#_+%&?=]{2,}(\.[a-z]{2,5})((?:\/[a-z0-9#-_+%?\.&=]*)+|\/|$))|([a-z0-9#._%+-]+@[a-z0-9.-]+\.[a-z]{2,})/gi;
  const matches = [];
  let match;

  while ((match = regex.exec(text)) !== null) {
    if (match[1]) {
      matches.push({ types: ['tag'], value: match[1], fullMatch: match[0] });
    } else if (match[2]) {
      matches.push({ types: ['tag'], value: match[2], fullMatch: match[0] });
    } else if (match[3]) {
      matches.push({ types: ['bold'], value: match[3], fullMatch: match[0] });
    } else if (match[4]) {
      matches.push({ types: ['italic'], value: match[4], fullMatch: match[0] });
    } else if (match[5]) {
      matches.push({ types: ['strike'], value: match[5], fullMatch: match[0] });
    } else if (match[6]) {
      const url = match[6];
      const href = url.startsWith('http') ? url : `https://${url}`;
      matches.push({ types: ['url'], value: url, href: [href], fullMatch: match[0] });
    } else if (match[11]) {
      const email = match[11];
      const mailto = `mailto:${email}`;
      matches.push({ types: ['url'], value: email, href: mailto, fullMatch: match[0] });
    }
  }

  const parts = [];
  let lastIndex = 0;
  matches.forEach((m) => {
    const index = text.indexOf(m.fullMatch, lastIndex);
    if (index > lastIndex) {
      parts.push({ types: [], value: text.slice(lastIndex, index) });
    }
    const item = { types: m.types, value: m.value };
    if (m.href) {
      item.href = m.href;
    }
    parts.push(item);
    lastIndex = index + m.fullMatch.length;
  });

  if (lastIndex < text.length) {
    parts.push({ types: [], value: text.slice(lastIndex) });
  }

  return parts;
}


function compileToJSON(str, pseudoMarkdown, recursive = false) {
  let result = []
  let minIndexOf = -1
  let minIndexOfKey = null

  let links = linkify.find(str)
  let minIndexFromLink = false

  if (links.length > 0) {
    minIndexOf = str.indexOf(links[0].value)
    minIndexFromLink = true
  }

  Object.keys(pseudoMarkdown).forEach(startingValue => {
    const io = str.indexOf(startingValue)
    if (io >= 0 && (minIndexOf < 0 || io < minIndexOf)) {
      minIndexOf = io
      minIndexOfKey = startingValue
      minIndexFromLink = false
    }
  })

  if (minIndexFromLink && minIndexOfKey !== -1) {
    let strLeft = str.substr(0, minIndexOf)
    let strLink = str.substr(minIndexOf, links[0].value.length)
    let strRight = str.substr(minIndexOf + links[0].value.length)
    result.push(strLeft)
    result.push(strLink)
    result = result.concat(compileToJSON(strRight, pseudoMarkdown, true))
    return result
  }

  if (minIndexOfKey) {
    let strLeft = str.substr(0, minIndexOf)
    const char = minIndexOfKey
    let strRight = str.substr(minIndexOf + char.length)

    if (str.replace(/\s/g, '').length === char.length * 2) {
      return [str]
    }

    const match = strRight.match(
      new RegExp(
        '^(' +
          (pseudoMarkdown[char].allowed_chars || '.') +
          '*' +
          (pseudoMarkdown[char].end ? '?' : '') +
          ')' +
          (pseudoMarkdown[char].end ? '(' + pseudoMarkdown[char].end + ')' : ''),
        'm',
      ),
    )

    if (!match || !match[1]) {
      strLeft = strLeft + char
      result.push(strLeft)
    } else {
      if (strLeft) {
        result.push(strLeft)
      }

      /// not sure
      // if (
      //   char !== '<usertag>' &&
      //   !(strRight.endsWith(`${match[1]}${char}`) || strRight.includes(`${match[1]}${char} `))
      // ) {
      //   return [str]
      // }
      // if (strLeft && strLeft.slice(-1) !== ' ') {
      //   return [str]
      // }

      if (
        char !== '<usertag>' &&
        !(
          strRight.endsWith(`${match[1]}${char}`) ||
          strRight.includes(`${match[1]}${char} `) ||
          strRight.includes(`${match[1]}${char}\n`) ||
          strRight.includes(`${match[1]}${char},`) ||
          strRight.includes(`${match[1]}${char}.`) ||
          strRight.includes(`${match[1]}${char}:`) ||
          strRight.includes(`${match[1]}${char};`) ||
          strRight.includes(`${match[1]}${char}!`) ||
          strRight.includes(`${match[1]}${char}?`) ||
          strRight.includes(`${match[1]}${char}(`) ||
          strRight.includes(`${match[1]}${char})`) ||
          strRight.includes(`${match[1]}${char}*`) ||
          strRight.includes(`${match[1]}${char}/`)
        )
      ) {
        return [str]
      }
      if (strLeft && strLeft.slice(-1) !== ' ' && strLeft.slice(-1) !== '\n') {
        return [str]
      }
      const object = {
        start: char,
        content: compileToJSON(match[1], pseudoMarkdown),
        end: match[2],
        type: pseudoMarkdown[char].type,
      }
      result.push(object)
      strRight = strRight.substr(match[0].length)
    }
    result = result.concat(compileToJSON(strRight, pseudoMarkdown, true))
    return result
  } else {
    if (str) {
      return [str]
    } else {
      return []
    }
  }
}

function compileToHTML(json, pseudoMarkdown) {
  const result = []

  json.forEach(item => {
    if (typeof item === 'string') {
      result.push({ types: [], value: item })
    } else {
      if (pseudoMarkdown[item.start]) {
        result.push(parseContent(item))
      }
    }
  })

  return result
}

function parseContent(item) {
  const result = []
  iterateContent(item, result, [])
  return result
}

function iterateContent(item, result, types) {
  item.content.forEach(it => {
    if (typeof it === 'string') {
      result.push({
        types: removeDuplicates(types.concat([item.type])),
        value: it,
      })
    } else {
      iterateContent(it, result, removeDuplicates([it.type].concat([item.type]).concat(types)))
    }
  })
}

function removeDuplicates(items) {
  return [...new Set(items)]
}

function linkifyResult(array) {
  const result = []

  array.forEach(arr => {
    const links = linkify.find(arr.value)

    if (links.length) {
      const spaces = arr.value.replace(links[0].value, '')
      result.push({ types: arr.types, value: spaces })

      arr.types = ['url'].concat(arr.types)
      arr.href = links[0].href
      arr.value = links[0].value
    }

    result.push(arr)
  })

  return result
}
