<template>
  <div v-dragscroll.x="true" class="quick-replies">
    <v-btn
      v-for="(template, index) in filteredTemplatesText"
      :key="index"
      :disabled="checkButtonDisable(template)"
      outlined
      class="mx-2 my-2 text-capitalize"
      dense
      small
      :color="fromChatwoot && template.isHaveButtons ? 'secondary' : 'primary'"
      @click="checkAndEmitSend(template)"
    >
      <span
        :style="$vuetify.theme.isDark ? 'color:white' : 'color:black'"
        class="truncate font-weight-medium text-subtitle-2"
        >{{ template.tag }}</span
      >
    </v-btn>
  </div>
</template>

<script>
import { dragscroll } from 'vue-dragscroll'

export default {
  name: 'RoomTemplatesText',

  directives: {
    dragscroll,
  },

  props: {
    filteredTemplatesText: { type: Array, required: true },
    selectItem: { type: Boolean, default: null },
    loadSendMessage: { type: Boolean, default: false },
    activeUpOrDown: { type: Number, default: null },
    message: { type: String, default: null },
    fromChatwoot: { type: Boolean, default: null },
  },

  emits: ['select-template-text', 'activate-item', 'reset-select'],

  data() {
    return {
      activeItem: null,
    }
  },

  methods: {
    checkButtonDisable(template) {
      // If loadSendMessage is true, disable the button
      if (this.loadSendMessage) {
        return true
      }

      // If not fromChatwoot, enable the button
      if (!this.fromChatwoot) {
        return false
      }

      // Destructure messages_templates from template.expand
      const { messages_templates } = template.expand
      // Destructure extraField and listButton from messages_templates, defaulting to an empty object if messages_templates is undefined
      const { extraField, listButton } = messages_templates || {}

      // Check if messages_templates has any keys and if either extraField or listButton is defined
      const hasValidTemplates = Boolean(Object.keys(template.expand).length && (extraField || listButton?.length))
      // Return true to disable the button if there are valid templates, false to enable it otherwise

      return hasValidTemplates
    },
    checkAndEmitSend(template) {
      template.fromChatwoot = this.fromChatwoot ? true : false
      this.$emit('select-template-text', template, true)
    },
  },

  watch: {
    filteredTemplatesText(val) {
      this.activeItem = 0
    },
    selectItem(val) {
      if (val) {
        if (this.message) {
          const qr = this.message.replace('/', '').toLowerCase()
          console.log('🚀 nyund ~ file: RoomTemplatesText.vue:88 ~ selectItem ~ qr :', qr)
          let foundIdx
          const foundQr = this.filteredTemplatesText.find((el, idx) => {
            foundIdx = idx
            return el.tag.toLowerCase() === qr
          })
          if (foundQr) {
            const template = this.filteredTemplatesText[foundIdx]
            template.isDisabled = this.checkButtonDisable(template)
            template.fromChatwoot = this.fromChatwoot ? true : false
            this.$emit('select-template-text', this.filteredTemplatesText[foundIdx])
          } else {
            this.$emit('reset-select')
          }
        }
      }
    },
    activeUpOrDown() {
      if (this.activeUpOrDown > 0 && this.activeItem < this.filteredTemplatesText.length - 1) {
        this.activeItem++
      } else if (this.activeUpOrDown < 0 && this.activeItem > 0) {
        this.activeItem--
      }
      this.$emit('activate-item')
    },
  },
}
</script>

<style scoped>
.quick-replies {
  display: flex;
  flex-flow: row;
  overflow-x: hidden;
  cursor: pointer;
}

.truncate {
  width: 8vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 768px) {
  .truncate {
    width: 11vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
