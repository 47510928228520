<template>
  <div class="vac-message-file-container">
    <div
      v-if="isImage"
      :ref="'imageRef' + index"
      class="vac-message-image-container"
      @mouseover="imageHover = true"
      @mouseleave="imageHover = false"
      @click.stop="openFile('preview')"
    >
      <progress-bar
        v-if="file.progress < 100"
        :style="{ top: `${imageResponsive.loaderTop}px` }"
        :progress="file.progress"
      />
      <loader
        v-else
        :show="isImageLoading"
        :from-message-file="true"
      >
        <template
          v-for="(idx, name) in $scopedSlots"
          #[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </loader>
      <div
        v-if="message.data.type !== 'document'"
        class="vac-message-image"
        :class="{
          'vac-image-loading':
            isImageLoading && message.senderId === currentUserId
        }"
      >
        <img
          v-if="message.data.type !== 'document'"
          :aspect-ratio="16/9"
          size="250px"
          :src="file.url"
          :class="{
            'loader-message-files': isImageLoading,
            'not-loading':!isImageLoading
          }"
          @error="onImageError"
        >
        </img>
        <transition name="vac-fade-image">
          <div
            v-if="imageHover && !isImageLoading"
            class="vac-image-buttons"
          >
            <div
              v-if="isFileValid"
              class="vac-svg-button vac-button-view"
              @click.stop="openFile('preview')"
            >
              <slot name="eye-icon">
                <svg-icon name="eye" />
              </slot>
            </div>
            <div
              v-if="isFileValid"
              class="vac-svg-button vac-button-download"
              @click.stop="openFile('download')"
            >
              <slot name="document-icon">
                <svg-icon name="document" />
              </slot>
            </div>
          </div>
        </transition>
      </div>

      <div
        v-else
        class="d-flex"
        style="background-color: #e9ecef; border-radius: 16px; padding: 8px 12px"
      >
        <img
          class="my-auto justify-center"
          width="20"
          height="20"
          :src="previewfile(file)"
        />

        <v-spacer />

        <div style="max-width: 60%">
          <span class="message-file-name my-auto mx-2 justify-center">{{
            $vuetify.breakpoint.xs ? truncateText(file.name) : file.name
          }}</span>
        </div>

        <v-spacer />

        <v-btn
          class="justify-center my-auto"
          icon
          fab
          @click="openFile(file, 'download')"
        >
          <v-icon color="#1976d2">
            {{ icons.mdiDownload }}
          </v-icon>
        </v-btn>
        <!-- <loader
          :loading-download="isLoadingDownload"
          :show="isLoadingDownload"
        >
        </loader> -->

        <!-- <div class="message-file-download-link vac-svg-button" @click.stop="openFile(file, 'download')">
            <slot name="document-icon" />
            <svg-icon name="document" />
            <loader :loading-download="isLoadingDownload" :show="isLoadingDownload"> </loader>
          </div> -->
      </div>

      <!-- <div
        v-else
        :style="{
          'border': '1px solid #1b1c21',
          'border-radius': '4px',
          'padding': '8px',
          'max-height': `${imageResponsive.maxHeight}px`
        }"
      >
        <div class="d-flex">
          <v-icon
            class="me-4 mx-auto"
            size="32px"
            color="#1F979E"
          >
            {{ icons.mdiFileDocument }}
          </v-icon>
          <span
            style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"
            class="text--white my-auto font-weight-bold mx-auto"
          >{{ $vuetify.breakpoint.xs ? truncateText(file.name) : file.name }}</span>
        </div>
      </div> -->
    </div>
    <div
      v-else-if="isVideo"
      class="vac-video-container"
      @click="videoClicked = true"
    >
      <div
        v-if="!videoClicked"
        style="background: #000000; height: 150px !important"
        class="d-flex"
        @click="videoClicked = true"
      >
        <!-- <v-btn
          width="85%"
          style="height: 150px !important"
          color="#000000"
        >
      </v-btn> -->
        <v-icon
          class="mx-auto my-auto text-center"
          size="50px"
          color="#FFFFFF"
        >
          {{ icons.mdiPlay }}
        </v-icon>
      </div>
      <div v-else>
        <video
          width="100%"
          height="100%"
          style="max-height: 200px;"
          controls
          autoplay
        >
          <source :src="file.url" />
        </video>
      </div>
    </div>
    <div
      v-else-if="isAudio"
      class="vac-video-container"
    >
      <audio
        width="100%"
        height="100%"
        style="max-height: 200px;"
        controls
        :src="file.url"
      >
      </audio>
    </div>
  </div>
</template>

<script>
import { isAudioFile } from '@/plugins/vue-advanced-chat/utils/media-file'
import { mdiFileDocument, mdiPlay, mdiDownload } from '@mdi/js'
import Loader from '../../../../components/Loader/Loader'
import ProgressBar from '../../../../components/ProgressBar/ProgressBar.vue'
import SvgIcon from '../../../../components/SvgIcon/SvgIcon'

const { isImageFile, isVideoFile } = require('../../../../utils/media-file')

export default {
  name: 'MessageFile',
  components: { SvgIcon, Loader, ProgressBar },

  props: {
    currentUserId: { type: [String, Number], required: true },
    message: { type: Object, required: true },
    file: { type: Object, required: true },
    index: { type: Number, required: true },
  },

  emits: ['open-file'],

  data() {
    return {
      imageResponsive: '',
      videoClicked: false,
      imageLoading: false,
      imageHover: false,
      icons: {
        mdiFileDocument,
        mdiPlay,
        mdiDownload
      },
      isImageLoading: false,
      isFileValid: true,
    }
  },

  computed: {
    // isImageLoading() {
    //   return this.file.url.indexOf('blob:http') !== -1 || this.imageLoading
    // },
    isImage() {
      return isImageFile(this.file)
    },
    isVideo() {
      return isVideoFile(this.file)
    },
    isAudio() {
      return isAudioFile(this.file)
    },
    isLoadingDownload() {
      return this.$store.getters['inbox/getLoadingDownloadFile']
    },
  },

  watch: {
    file: {
      immediate: true,
      handler() {
        this.checkImgLoad()
      },
    },
    isLoadingDownload(val, newVal) {
      if (val) {
        this.imageLoading = true
      } else this.imageLoading = false
    },
  },

  mounted() {
    const ref = this.$refs[`imageRef${this.index}`]

    if (ref) {
      this.imageResponsive = {
        maxHeight: ref.clientWidth - 18,
        loaderTop: ref.clientHeight / 2 - 9,
      }
    }
  },

  methods: {
    previewfile(file) {
      if (this.fromChatwoot) {
        if (file.preview.includes('pdf')) {
          return '/file-manager/icons/application-pdf.svg'
        }
        if (file.preview.includes('csv')) {
          return 'https://cdn-icons-png.flaticon.com/512/8242/8242984.png'
        }
        if (file.preview.includes('xlsx')) {
          return '/file-manager/icons/application-vnd.ms-excel.svg'
        }
        if (file.preview.includes('txt')) {
          return 'https://cdn-icons-png.flaticon.com/512/3979/3979306.png'
        }
        if (file.preview.includes('docx')) {
          return '/file-manager/icons/application-msword-template.svg'
        }

        return '/file-manager/icons/text.svg'
      }

      if (file.type === 'pdf') {
        return '/file-manager/icons/application-pdf.svg'
      }
      if (file.type === 'csv') {
        return 'https://cdn-icons-png.flaticon.com/512/8242/8242984.png'
      }
      if (file.type.includes('spreadsheetml') || file.name.includes('xlsx')) {
        return '/file-manager/icons/application-vnd.ms-excel.svg'
      }
      if (file.type === 'plain') {
        return 'https://cdn-icons-png.flaticon.com/512/3979/3979306.png'
      }
      if (file.type.includes('document')) {
        return '/file-manager/icons/application-msword-template.svg'
      }

      return '/file-manager/icons/text.svg'
    },
    truncateText(item) {
      if (item.length > 15) {
        return `${item.slice(0, 15)}...`
      }

      return item
    },
    onImageError(event) {
      event.target.src = 'https://placehold.co/600x400?text=Image+not+found'
      event.target.class = ''
      this.isImageLoading = false
      this.isFileValid = false
    },
    checkImgLoad() {
      if (!isImageFile(this.file)) return
      this.isImageLoading = true
      const image = new Image()
      image.src = this.file.url
      image.addEventListener('load', () => (this.isImageLoading = false))
    },
    openFile(action) {
      this.$emit('open-file', { file: this.file, action })
    },
  },
}
</script>
<style>
.loader-message-files {
  width: 10vw !important;
  height: 10vw;
}
.not-loading {
  height: auto;
  max-width: -webkit-fill-available;
  max-width: -webkit-fill-available;
  max-height: -webkit-fill-available;
}
</style>
